import React, { createRef, useEffect } from "react";
import logo from "../logo.png";
import google from "../google-play-badge.png";
import huawei from "../huawei-badge.png";
import appsore from "../appstore-badge.svg";
import "../App.css";

const Home = () => {
  const videoRef = createRef(null);
  useEffect(() => {
    videoRef.current.volume = 0.3;
  }, []);
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }
  const getOs = () => {
    const os = ["Windows", "Linux", "Mac"]; // add your OS values
    return os.find((v) => navigator.appVersion.indexOf(v) >= 0);
  };
  return (
    <div className="App" style={{overflowX:"hidden"}}>
      <header className="App-header">
        <img src="img/site/radio-dim-logo.svg" style={{ height: "115px" }} />
        <h1
          className="text-center mt-1"
          style={{ fontWeight: "normal", fontSize: "28px" }}
        >
          Рекламна група "Радіодім"
        </h1>
        <div className="mt-5" style={{ overflow: "auto",width: "75%", flexDirection: "row", whiteSpace:"nowrap" }}>
          <a
            href="https://play.radiodim.rv.ua"
            style={{ marginLeft: 10, marinRight: 10 }}
          >
            <img className="mb-3" src="img/xit.png" height={150} alt="logo" />
          </a>
          <a
            href="https://play.radiodim.rv.ua"
            style={{ marginLeft: 10, marinRight: 10 }}
          >
            <img className="mb-3" src="img/nashe.png" height={150} alt="logo" />
          </a>
          <a
            href="https://play.radiodim.rv.ua"
            style={{ marginLeft: 10, marinRight: 10 }}
          >
            <img className="mb-3" src="img/relax.png" height={150} alt="logo" />
          </a>
          <a
            href="https://play.radiodim.rv.ua"
            style={{ marginLeft: 10, marinRight: 10 }}
          >
            <img className="mb-3" src="img/lyks.png" height={150} alt="logo" />
          </a>
          <a
            href="https://play.radiodim.rv.ua"
            style={{ marginLeft: 10, marinRight: 10 }}
          >
            <img
              className="mb-3"
              src="img/maximum.png"
              height={150}
              alt="logo"
            />
          </a>
          <a
            href="https://play.radiodim.rv.ua"
            style={{ marginLeft: 10, marinRight: 10 }}
          >
            <img className="mb-3" src="img/nv.png" height={150} alt="logo" />
          </a>
        </div>
        <div className="mt-4 container mb-4">
          <h2 style={{ fontWeight: "normal", fontSize: "24px" }}>
            Виготовлення та розміщення реклами в ефірі популярних українських
            радіостанцій:
          </h2>
          <p style={{ fontWeight: "bold" }}>
            <br />
            "Люкс Fm", "Maximum", "Хiт Fm", "Наше радiо", "Relax", "НВ",
          </p>
          <p>
            <br />
            а також онлайн-радіо "Rocks", "Jazz", "Kiss Fm", "Мелодія", "Перець
            Fm", "Байрактар" і "Радіодім" <br />
            у Рiвному, Рівненській області та у будь-якому іншому регіоні
            України.
            <br />
            <br />
            Розміщення реклами у мобільному додатку "Радіодім". <br />
            Завантажити додаток ви можете у своєму маркеті, ввівши у пошуку
            слово "Радіодім", <br />
            або на нашому сайті за посиланнями нижче.
            <br />
            <br />
            <br />
            <a className="a-clear" href="tel: +38 (050) 40 40 006">
              +38 (050) 40 40 006
            </a>
            <br />
            <a className="a-clear" href="mailto: ad.radiodim@gmail.com">
              ad.radiodim@gmail.com
            </a>
            <br />
            <a
              className="a-clear"
              target="_blank"
              href="http://www.google.com/maps/place/50.621075981541914, 26.249530285051012?q=Радіодім"
            >
              вул. Поштова, 11, (БЦ "Норд", 2-й поверх) м.Рівне, Україна, 33028
            </a>
            <br />
            Facebook:{" "}
            <a
              className="a-clear"
              target="_blank"
              href="https://www.facebook.com/radiodim.rv.ua"
            >
              https://www.facebook.com/radiodim.rv.ua
            </a>
          </p>
        </div>
        <div style={{ backgroundColor: "white", paddingTop: 10 }}>
          <video
            ref={videoRef}
            style={{ height: "100%", width: "75%" }}
            controls
            autoplay
            poster="img/preview.png"
          >
            <source src="video/main-vid.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <p className="mt-4 mx-3">
          {/* У зв'язку з кібератаками сайт Радіодім тимчасово не працює
          <br />
          <br />
          Дякуємо за розуміння */}
          <br />
          <br />
          Підтримай українську армію!{" "}
          <a className="mt-2 App-link" href="https://savelife.in.ua">
            {" "}
            savelife.in.ua
          </a>
        </p>
        {/* <img  src={logo} height={100} alt="Радіодім Рівне" /> */}

        <h4 className="mt-5">Додаток Радіодім:</h4>
        <div className="mt-5 mb-2">
          <a href="https://play.radiodim.rv.ua">
            <img
              style={{ height: "104px" }}
              alt="Play Radiodim Logo Badge"
              src="img/site/play-radiodim-badge.svg"
            />
          </a>
        </div>
        {getOs() == "Windows" && getMobileOperatingSystem() == "unknown" ? (
          // <div className="mb-3">
          //   <ms-store-badge
          //     productid="9N48MN6KVLK6"
          //     window-mode="popup"
          //     animation="off"
          //   ></ms-store-badge>
          // </div>
          <div className="mt-2 mb-2">
          <a href="https://apps.microsoft.com/store/detail/radiodim-%E2%80%94-online-radio-player/9N48MN6KVLK6">
            <img
              style={{ height: "104px" }}
              alt="Windows Radiodim"
              src="img/site/microsoft.png"
            />
          </a>
        </div>
        ) : null}
        <div className="row mt-3">
          {getMobileOperatingSystem() == "iOS" ? (
            <a
              href="https://apps.apple.com/app/radiodim/id1582682192"
              className="col-12"
            >
              <img className="mb-3" src={appsore} height={75} alt="logo" />
            </a>
          ) : getMobileOperatingSystem() == "Android" ? (
            <>
              <a
                href="https://play.google.com/store/apps/details?id=ua.radiodim"
                className="col-12 col-md-6"
              >
                <img className="mb-3" src={google} height={75} alt="logo" />
              </a>
              <a
                href="https://appgallery.huawei.com/app/C104981889"
                className="col-12 col-md-6"
              >
                <img className="mb-3" src={huawei} height={75} alt="logo" />
              </a>
            </>
          ) : (
            <>
              <a
                href="https://play.google.com/store/apps/details?id=ua.radiodim"
                className="px-2 col-md-4"
              >
                <img className="mb-3" src={google} height={75} alt="logo" />
              </a>
              <a
                href="https://appgallery.huawei.com/app/C104981889"
                className="px-2 col-md-4"
              >
                <img className="mb-3" src={huawei} height={75} alt="logo" />
              </a>
              <a
                href="https://apps.apple.com/app/radiodim/id1582682192"
                className="px-2 col-md-4"
              >
                <img className="mb-3" src={appsore} height={75} alt="logo" />
              </a>
            </>
          )}
        </div>
        <a
          href="https://drive.google.com/file/d/1SXQxti4mBQDyQ1GXEOUk0v1vRpWpG3mJ/view?usp=sharing"
          className="mt-2 App-link"
        >
          APK
        </a>
        {/* <a href="https://play.radiodim.rv.ua" className="mt-4 App-link">
          Слухати онлайн
        </a> */}
        <a href="policy" className="mt-4 App-link mb-4">
          Політика конфіденційності
        </a>
      </header>
    </div>
  );
};
export default Home;
